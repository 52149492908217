<template>
    <menu-layout :loading="loading">
        <row v-if="selected_task">
            <col8>
                <autocomplete :value="selected_task" @select="select_task" :options="tasks"></autocomplete>
            </col8>
            <col4 v-if="progress">
                {{progress}} left
            </col4>
        </row>
        <annotate :processing_tasks=true :filters="filters" @update_progress="update_progress" v-if="initiated"></annotate>
    </menu-layout>
</template>

<script>
  import MenuLayout from "@/components/layout/MenuLayout";
  import Annotate from "@/pages/Annotate";
  import Row from "@/components/layout/Row";
  import Col8 from "@/components/layout/Col8";
  import Col4 from "@/components/layout/Col4";
  import Autocomplete from "@/components/Autocomplete";
  export default {
    data () {
      return {
        'loading': false,
        'filters': false,
        'initiated': false,
        'progress': false,
        'selected_task': false,
        'tasks': []
      }
    },
    mounted() {
      this.initiate()
    },
    methods: {
      async initiate() {
        this.$set(this, 'loading', true)
        let tasks = await this.$api.user_task.all_for_user()
        
        // sort tasks by name
        tasks.sort((a, b) => (a.name > b.name) ? 1 : -1)

        this.$set(this, 'tasks', tasks)

        for (let task of tasks) {
          if(!await this.task_completed(task)) {
            this.$set(this, 'filters', task.filter)
            this.$set(this, 'selected_task', task)
            break
          }
        }
        
        this.$set(this, 'initiated', true)
        this.$set(this, 'loading', false)
      },
      async task_completed (task) {
        let result = await this.$api.asset.next_asset(task.filter);
        if(result.done === true) {
          return true
        }
        return false
      },
      select_task (task) {
        if(task) {
          this.$set(this, 'filters', task.filter)
          this.$set(this, 'selected_task', task)
        }
      },
      update_progress (progress) {
        console.log(progress)
        if(progress == 0) {
          this.initiate();
        }
        this.$set(this, 'progress', progress)
      }
    },
    mixins: [],
    name: "Tasks",
    components: {Autocomplete, Col4, Col8, Row, Annotate, MenuLayout}
  }
</script>

<style scoped>

</style>