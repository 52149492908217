<template>
  <div style="background-color: white;">
    <checkbox style="float:right" v-model="checkboxValue" @input="toggleSelect"></checkbox>
    <row>
      <col-8>
        <annotated-image v-if="annotation" :max_height="200" @canvas_selected="selectAsset" :image_url="asset.path" :zoom_enabled="false" :annotations="[annotation]"></annotated-image>
      </col-8>
      <col-4>
        <br>
        <annotation-suggestion-crop @select_asset="selectAsset(asset.id)" v-if="image_data" :annotation_type="annotation_type" :image_data="image_data"></annotation-suggestion-crop>
      </col-4>
    </row>
    <row>
      {{suggestion_title}}
    </row>
  </div>
</template>

<script>
import AnnotatedImage from '@/components/AnnotatedImage'
import Checkbox from '@/components/Checkbox'
import Row from '@/components/layout/Row.vue'
import Col8 from '@/components/layout/Col8.vue'
import Col4 from '@/components/layout/Col4.vue'
import AnnotationSuggestionCrop from '@/components/AnnotationSuggestionCrop'

export default {
  name: "AnnotationSuggestion.vue",
  props: {
    'suggestion': {default: ''},
    'asset': {default: ''},
  },
  components: {AnnotatedImage, Row, Col8, Col4, Checkbox, AnnotationSuggestionCrop},
  data() {
    return {
      checkboxValue: false,
      image_data: null,
    }
  },
  methods: {
    async loadCrop() {
      if (this.annotation) {
        const firstAnnotation = this.annotation;
        let coords = firstAnnotation.coords;
        let bboxCoordsString = JSON.stringify(coords);
        let data = await this.$api.asset.get_crop(this.asset.path, bboxCoordsString, this.asset.id);
        this.image_data = data.crops[0].crop;
      }
    },
    findMatchedAnnotation() {
      let matchedAnnotation = null;
      for (const annotation of this.asset.annotations) {
        if (annotation.id === this.annotation_id) {
          matchedAnnotation = annotation;
          break;
        }
      }
      return matchedAnnotation;
    },
    toggleSelect() {
      this.$emit('select_toggled', this.suggestion_id, this.checkboxValue)
    },
    selectAsset() {
      this.$emit('select_asset', this.asset.id)
    },
  },
  computed: {
    annotation_type() {
      if (this.annotation) {
        const firstAnnotation = this.annotation;
        if (firstAnnotation.type === "box") {
          return 1;
        } else if (firstAnnotation.type === "point") {
          return 2;
        }
      }
      return 1;
    },
    annotation() {
      const parsedSuggestion = JSON.parse(this.suggestion.suggestion);
      const { action } = parsedSuggestion;

      if (action === "add_bounding_box") {
        const { coords, label } = parsedSuggestion;
        return {
          conf: 1,
          coords: coords,
          id: this.annotation_id,
          label: label,
          type: "box",
          color_values: [53, 209, 15],
        };
      } else if (action === "add_keypoint") {
        const { coords, label } = parsedSuggestion;
        return {
          conf: 1,
          coords: coords,
          id: this.annotation_id,
          label: label,
          type: "point",
          color_values: [53, 209, 15],
        };
      } else if (action === "delete_annotation") {
        let matchedAnnotation = this.findMatchedAnnotation();
        if (matchedAnnotation) {
          let coords = matchedAnnotation.coords;
          let label = matchedAnnotation.label;
          return {
            conf: 1,
            coords: coords,
            id: this.annotation_id,
            label: label,
            type: "box",
            color_values: [255, 0, 0], 
          };
        }
      } else if (action === "rename_label_annotation") {
        let matchedAnnotation = this.findMatchedAnnotation();
        if (matchedAnnotation) {
          let coords = matchedAnnotation.coords;
          let label = matchedAnnotation.label;
          return {
            conf: 1,
            coords: coords,
            id: this.annotation_id,
            label: label,
            type: "box",
            color_values: [25, 118, 210],
          };
        }
      }
      return null;
    },
    annotation_id() {
      let initial_suggestion = this.suggestion;
      let annotation_id = initial_suggestion.annotation_id;
      return annotation_id;
    },
    suggestion_id() {
      let initial_suggestion = this.suggestion;
      let suggestion_id = initial_suggestion.id;
      return suggestion_id;
    },
    suggestion_title() {
      if (this.suggestion.suggestion) {
        let parsed_suggestion = JSON.parse(this.suggestion.suggestion);
        if (parsed_suggestion.action === "rename_label_annotation") {
          let suggested_label = parsed_suggestion.label_name;
          let matchedAnnotation = this.findMatchedAnnotation();
          if (matchedAnnotation) {
            let original_label = matchedAnnotation.label;
            return this.$t('suggestions.rename_label_annotation', {
              original_label,
              suggested_label
            });
          }
        } else if (parsed_suggestion.action === "delete_annotation") {
          let matchedAnnotation = this.findMatchedAnnotation();
          if (matchedAnnotation) {
            let original_label = matchedAnnotation.label;
            return this.$t('suggestions.delete_annotation', {
              original_label
            });
          }
        } else if (parsed_suggestion.action === "add_bounding_box") {
          let suggested_label = parsed_suggestion.label_name;
          return this.$t('suggestions.add_bounding_box', {
            suggested_label
          });
        } else if (parsed_suggestion.action === "add_keypoint") {
          let suggested_label = parsed_suggestion.label_name;
          return this.$t('suggestions.add_keypoint', {
            suggested_label
          });
        }
        // let label_change = parsed_suggestion.label_change;
        return parsed_suggestion;
      } else {
        return '';
      }
    }
  },
  mounted() {
    this.loadCrop();
  }
}
</script>

<style scoped>
</style>
