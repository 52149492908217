<template>
    <modal @close_modal="close" :loading="loading">
      <template v-slot:buttons>
        <annotate-buttons
            v-if="asset"
            :in_modal="true"
            @stored="removeItemFromGrid"
            :annotations="annotations"
            :asset_id_history="asset_id_history"
            :asset_id="asset_id_cached"
            :asset_in_validation_set="asset_in_validation_set"
            @annotate-limit-reached="handleAnnotationLimitReached"
            >
        </annotate-buttons>
      </template>
      <template v-slot:content>
        <row v-if="asset">
          <col9>
            <div v-if="annotationLimitReached">
              <show-alert :alert-message="annotationLimitReachedMessage"></show-alert>
            </div>
            <annotated-image
                v-if="asset_url"
                :zoom_enabled="true"
                :image_url="asset_url"
                :annotations="annotations"
                @update_coords="updateAnnotation"
                @delete_annotation="deleteAnnotation"
                @select="selectAnnotation"
                @unselect="unselectAnnotation"
                @add_annotation_deselect_others="add_annotation_deselect_others"
                :contrast="contrast"
                :brightness="brightness"
            ></annotated-image>
            <meta-grid :meta="meta"></meta-grid>
          </col9>
          <col3>
            <row>
              <col12>
                <v-btn color="primary" v-if="model_id && !model_results_shown" @click.native="runModel(model_id)">
                  Show model results
                </v-btn>
                <v-btn color="primary" v-else-if="model_id" @click.native="resetAnnotations">
                  Hide model results
                </v-btn>
                <autocomplete v-else-if="active_models.length > 0 && (is_admin || is_manager)"
                    :placeholder="$t('annotate.placeholder')"
                    :prepend_icon="prepend_icon_run_model"
                    :options="active_models"
                    :name="'label'"
                    @select="runModel"
                ></autocomplete>
              </col12>
            </row>
            <row>
              <col5>
                {{$t('annotate.brightness')}}
              </col5>
              <col6>
                <input type="range" id="brightness" v-model="brightness" min="-1" max="1" step="0.003921">
              </col6>
            </row>
            <row>
              <col5>
                {{$t('annotate.contrast')}}
              </col5>
              <col6>
                <input type="range" id="contrast" v-model="contrast" min="-1" max="1" step="0.003921">
              </col6>
            </row>
            <row>
              <col12>
                <annotate-labels :disabled="annotationLimitReached" @add_annotation="addAnnotationClearSelection" :type="'box'"></annotate-labels>
                <annotate-labels :disabled="annotationLimitReached" @add_annotation="addAnnotationClearSelection" :type="'point'"></annotate-labels>
                <annotate-label-groups @add_annotation="addAnnotationClearSelection" :type="'group'"></annotate-label-groups>
              </col12>
            </row>

            <row>
              <col12>
                <hr/>
              </col12>
            </row>

            <row>
              <col12>
                <v-btn v-if="hasAnnotationSuggestions && show_annotation_suggestions" @click="show_suggestions" color="primary" style="float:none; margin-left:0px; margin-top: 15px" id="show_annotation_suggestion" class="button">
                  {{$t('annotate.btn_show')}}
                </v-btn>
                <v-btn v-if="!show_annotation_suggestions" @click="hide_suggestions" color="primary" style="float:none; margin-left:0px; margin-top: 15px" id="hide_annotation_suggestion" class="button">
                  {{$t('annotate.btn_hide')}}
                </v-btn>
                <v-btn v-if="hasEncodedBoundingBoxes && !show_bounding_box_meta" @click="show_bbox_meta" color="primary" style="float:none; margin-left:0px; margin-top: 15px" id="show_bounding_box_meta" class="button">
                  {{$t('annotate.btn_show_meta')}}
                </v-btn>
                <v-btn v-if="show_bounding_box_meta" @click="hide_bbox_meta" color="primary" style="float:none; margin-left:0px; margin-top: 15px" id="hide_bounding_box_meta" class="button">
                  {{$t('annotate.btn_hide_meta')}}
                </v-btn>
                <annotations-list
                    @update_label="updateAnnotationLabel"
                    @clear="clearAnnotations"
                    @select="selectAnnotation"
                    @unselect="unselectAnnotation" 
                    :annotations="annotations"
                    ></annotations-list>
              </col12>
              <col12>
                <refresh-button @click.native="resetAnnotations"></refresh-button>
              </col12>
            </row>
            <row>
              <col12>
                <hr/>
              </col12>
            </row>
            <row v-if="is_admin || is_manager">
              <col12>
                <v-switch
                  style="margin-top: 0px; margin-left: 5px; padding-top: 0px"
                  inset
                  :value="true"
                  @change="toggleSwitch($event)"
                  :input-value="asset_in_validation_set===true"
                  :label="'in validation set'"
                  color="primary"
              ></v-switch>
              </col12>
            </row>

            <row>
              <col12>
                {{$t('annotate.last_updated')}}<br />
                <mutation v-if="last_mutation" :key="last_mutation.id" :mutation="last_mutation"></mutation>
              </col12>
            </row>
          </col3>
        </row>
      </template>
    </modal>
</template>

<script>
import Modal from "@/components/Modal";
import AnnotateMixin from "@/mixins/AnnotateMixin";
import AnnotatedImage from "@/components/AnnotatedImage";
import AnnotationsList from "@/components/AnnotationsList";
import AnnotateLabels from "@/components/AnnotateLabels";
import AnnotateButtons from "@/components/AnnotateButtons";
import AnnotateLabelGroups from "@/components/AnnotateLabelGroups";
import Row from "@/components/layout/Row";
import Col9 from "@/components/layout/Col9";
import Col12 from "@/components/layout/Col12";
import LoadSettingsMixin from "@/mixins/LoadSettingsMixin";
import LoadModelsMixin from "@/mixins/LoadModelsMixin";
import Col3 from "@/components/layout/Col3";
import Col5 from "@/components/layout/Col5";
import Col6 from "@/components/layout/Col6";
import Mutation from "@/components/Mutation";
import RefreshButton from "@/components/form/RefreshButton";
import Annotation from "@/classes/annotation";
const { v4: uuidV4 } = require('uuid');
import Autocomplete from "@/components/Autocomplete.vue";
import CurrentUserMixin from "@/mixins/CurrentUserMixin.vue";
import ShowAlert from "@/components/ShowAlert.vue";
import MetaGrid from "@/components/MetaGrid.vue";
import LoadLabelsMixin from "@/mixins/LoadLabelsMixin";

export default {
  name: "AnnotateModal",
  components: {
    Autocomplete,
    RefreshButton,
    Mutation,
    Col6,
    Col5,
    Col3,
    Col12,
    Col9, Row, AnnotateLabelGroups, AnnotateButtons, AnnotateLabels, AnnotatedImage, Modal, ShowAlert, AnnotationsList, MetaGrid},
  props: ['asset_id', 'model_id'],
  data() {
    return {
      'asset': null,
      'meta': '',
      'brightness': 0,
      'contrast': 0,
      'asset_url': false,
      'loading': true,
      'asset_id_history': [],
      'focus': false,
      'asset_id_cached': null,
      'model_results_shown': false,
      'show_annotation_suggestions': true,
      'show_bounding_box_meta' : false,
      'annotationLimitReached': false,
    }
  },
  computed: {
    annotationLimitReachedMessage() {
      return this.getLimitMessage('annotate', 'ANNOTATION_LIMIT');
    },
    prepend_icon_run_model () {
      return this.run_model_loading ? 'fas fa-spinner fa-spin' : 'far fa-circle-play';
    },
    last_mutation () {
      if(this.mutations.length === 0) {
        return false;
      }
      return this.mutations[this.mutations.length - 1];
    },
    'select_selected' () {
      if(!this.focus) {
        return false;
      }
      return this.focus.tagName.toUpperCase() === 'INPUT'
    },
    hasAnnotationSuggestions() {
      return this.annotation_suggestions.length > 0;
    },
    asset_in_validation_set() {
      if(!this.asset) {
        return null;
      }
      return this.asset.in_validation_set;
    },
  },
  methods: {
    handleAnnotationLimitReached() {
      this.$set(this, 'annotationLimitReached', true)
    },
    toggleSwitch(value) {
      if (value) {
        this.$set(this.asset, 'in_validation_set', true);
      } else {
        this.$set(this.asset, 'in_validation_set', false);
      }
    },
    runModel(model_or_model_id) {

      if(this.run_model_timeout) {
        clearTimeout(this.run_model_timeout);
      }

      if(!model_or_model_id) {
        this.resetAnnotations();
        this.$set(this, 'run_model_loading', false)
        return;
      }


      let model_id = model_or_model_id;
      if (typeof model_or_model_id === 'object') {
        model_id = model_or_model_id.id;
      }
      this.resetAnnotations();
      this.loadModalAnnotations(model_id, this.asset_id_cached);
      this.$set(this, 'model_results_shown', true)
    },
    focusIn (event) {
      const el = event.target
      this.$set(this, 'focus', el)
    },
    focusOut () {
      this.$set(this, 'focus', false)
    },
    close() {
      this.$set(this, 'asset_id_cached', null)
      this.$set(this, 'asset', null)
      this.$emit('close_modal')
    },
    removeItemFromGrid() {
      this.$emit('remove_item', this.asset_id_cached);
      this.close();
    },
    addEvents: function(e) {

      let activeElement = document.activeElement;
      let inputs = ['input', 'select', 'button', 'textarea'];

      if (activeElement && inputs.indexOf(activeElement.tagName.toLowerCase()) !== -1) {
          return false;
      }

      if (!this.select_selected && e.keyCode === 32) { //space
        e.stopPropagation();
        e.preventDefault();

        this.close()
      }
    },
    show_suggestions() {
      this.annotation_suggestions.forEach(annotation_suggestion => {
        let suggestion = JSON.parse(annotation_suggestion.suggestion)
        console.log(suggestion)
        if(suggestion.action === 'add_bounding_box') {
          let suggested_label_id = suggestion.label_id
          let suggested_label_name = this.labels.find(label => label.id == suggested_label_id).name
          let id = uuidV4();
          let color = [0,180,0]
          let annotation = new Annotation(id, 'box', JSON.parse(suggestion.coords), suggestion.confidence, suggested_label_name, color);
          this.addAnnotationClearSelection(annotation)
        }
        else if(suggestion.action === 'add_keypoint') {
          let suggested_label_id = suggestion.label_id
          let suggested_label_name = this.labels.find(label => label.id == suggested_label_id).name
          let id = uuidV4();
          let color = [0,180,0]
          let annotation = new Annotation(id, 'point', JSON.parse(suggestion.coords), suggestion.confidence, suggested_label_name, color);
          this.addAnnotationClearSelection(annotation)
        }
        else if(suggestion.action === 'delete_annotation') {
          let annotations = this.annotations.filter(annotation => annotation.id !== annotation_suggestion.annotation_id)
          this.$set(this, 'annotations', annotations)
        }
        else if(suggestion.action === 'rename_label_annotation') {
          let suggested_label_id = suggestion.suggested_label_id
          let suggested_label_name = this.labels.find(label => label.id === suggested_label_id).name
          let annotation_ids = this.annotations.map(annotation => annotation.id)
          let index = annotation_ids.indexOf(annotation_suggestion.annotation_id)
          this.annotations[index].label = suggested_label_name
        }
      });
      this.show_annotation_suggestions = false;
    },
    hide_suggestions() {
      this.$set(this, 'annotations', JSON.parse(JSON.stringify(this.initial_annotations)));
      this.show_annotation_suggestions = true;
    },
    hide_bbox_meta() {
      this.$set(this, 'annotations', JSON.parse(JSON.stringify(this.initial_annotations)));
      this.show_bounding_box_meta = false;
    },

  },
  watch: {
    async asset_id_cached(n, o) {
      if (!o) {
        let asset = await this.$api.asset.get(n)
        this.$set(this, 'asset', asset);
      }
    },
  },
  mounted() {
    this.load()
    this.$set(this, 'asset_id_cached', this.asset_id)
    document.addEventListener('focusin', this.focusIn)
    document.addEventListener('focusout', this.focusOut)
    window.addEventListener('keydown', this.addEvents);
  },
  destroyed () {
    document.removeEventListener('focusin', this.focusIn)
    document.removeEventListener('focusout', this.focusOut)
    window.removeEventListener('keydown', this.addEvents);
  },
  mixins: [AnnotateMixin, LoadSettingsMixin, LoadModelsMixin, CurrentUserMixin, LoadLabelsMixin]
}
</script>

<style scoped>

</style>