export default class Job {
    constructor(box21_api) {
        this.api = box21_api;
    }

    async start_train_job(train_config_id) {
        let formData = new FormData();
        formData.append('project_id', sessionStorage.getItem('project_id'));
        formData.append('train_config_id', train_config_id);
        return this.api.post_with_token('/start-train-job', formData);
    }

    async start_export_job(model, export_type) {
        let formData = new FormData();
        formData.append('model_id', model.id);
        formData.append('export_type', export_type);
        formData.append('project_id', sessionStorage.getItem('project_id'));
        return this.api.post_with_token('/start-export-job', formData);
    }

    async start_calculate_conf_matrix_job(model_id) {
        let formData = new FormData();
        formData.append('project_id', sessionStorage.getItem('project_id'));
        formData.append('model_id', model_id);
        return this.api.post_with_token('/conf-matrix-items/calculate', formData);
    }

    async get_active_analyse_conf_matrix_job(model_id) {
        return this.get_active_jobs('calculate_conf_matrix_items', false, model_id)
    }

    async get_export_jobs(model_id) {
        let formData = new FormData();
        formData.append('project_id', sessionStorage.getItem('project_id'));
        formData.append('model_id', model_id);
        return this.api.post_with_token('/get-export-jobs', formData);
    }

    async get_active_jobs(task, description, meta_contains, model_id) {
        let formData = new FormData();
        formData.append('task', task);
        let project_id = sessionStorage.getItem('project_id')

        if(!project_id) {
            return null
        }
        if(typeof project_id == 'undefined') {
            return
        }
        if(project_id == 'undefined') {
            return
        }
        
        formData.append('project_id', project_id)

        if(description) {
            formData.append('description', description);
        }

        if(model_id) {
            formData.append('model_id', model_id);
        }
        
        if(meta_contains) {
            formData.append('meta_contains', meta_contains);
        }

        return this.api.post_with_token('/get-active-jobs', formData);
    }

    async add_job(job, description) {
        let formData = new FormData();
        formData.append('project_id', sessionStorage.getItem('project_id'));
        formData.append('job', job);
        if(description) {
            formData.append('description', description);
        }
        return this.api.post_with_token('/job/add', formData);
    }

    async load(job) {
        let formData = new FormData();
        formData.append('job_id', job.id);
        formData.append('project_id', sessionStorage.getItem('project_id'));
        return this.api.post_with_token('/job', formData);
    }

    async delete(job) {
        let formData = new FormData();
        formData.append('job_id', job.id);
        formData.append('project_id', sessionStorage.getItem('project_id'));
        return this.api.post_with_token('/job/delete', formData);
    }

    async all(options, filter) {
        let itemsPerPage = options.itemsPerPage
        let page = options.page;
        let formData = new FormData();
        formData.append('project_id', sessionStorage.getItem('project_id'))
        formData.append('page', page)
        formData.append('sortBy', options.sortBy);
        formData.append('sortDesc', options.sortDesc);
        formData.append('filter', filter);
        formData.append('itemsPerPage', itemsPerPage)
        return this.api.post_with_token('/jobs', formData);
    }
}